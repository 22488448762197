<template>
  <div>
    <div class="pos intro-y grid grid-cols-12 gap-2 mt-2">
      <!-- BEGIN: Ticket -->
      <div class="col-span-12">
        <div class="intro-y pr-1 grid grid-cols-12">
          <div
            class="pos__tabs nav nav-tabs justify-center col-span-9"
            role="tablist"
          >
            <a
              id="single-stock"
              data-toggle="tab"
              data-target="#single-stock-issue"
              href="javascript:;"
              class="flex-1 py-1 rounded-md text-center box border mx-1 active"
              role="tab"
              aria-controls="single-stock-issue"
              aria-selected="true"
              >Single Stock Issue</a
            >
            <a
              id="multi-stock"
              data-toggle="tab"
              data-target="#multi-stock-issue"
              href="javascript:;"
              class="flex-1 py-1 rounded-md text-center box border mx-1"
              role="tab"
              aria-controls="multi-stock-issue"
              aria-selected="false"
              >Multi Stock Issue</a
            >
          </div>
        </div>
        <div class="tab-content">
          <div
            id="single-stock-issue"
            class="tab-pane active"
            role="tabpanel"
            aria-labelledby="single-stock-tab"
          >
            <div class="w-full">
              <SingleIssue ref="SingleIssueMenu" />
            </div>
          </div>
          <div
            id="multi-stock-issue"
            class="tab-pane"
            role="tabpanel"
            aria-labelledby="multi-stock-tab"
          >
            <div class="w-full">
              <MultiIssue ref="MultiIssueMenu" />
            </div>
          </div>
        </div>
      </div>
      <!-- END: Ticket -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { defineComponent } from 'vue'
import SingleIssue from '@/views/urgent-view/SingleIssue.vue'
import MultiIssue from '@/views/urgent-view/MultiIssue.vue'

export default defineComponent({
  components: {
    SingleIssue,
    MultiIssue
  },

  setup() {
    return {}
  }
})
</script>
