<template>
  <!-- BEGIN: Profile Info -->
  <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="page_data.error">{{ page_data.error }}</div>
  </div>
  <div id="basic-non-sticky-notification-packet-assign" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
          <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
          <div class="font-medium text-black">{{ page_data.message }}</div>
      </div>
  </div>
  <div>

      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <h2 class="text-lg font-medium mr-auto">Urgent Issue / Urgent Release</h2>
      <div class="flex items-center my-3">
        <div class="form-check">
          <input
            @click="focusScan"
            class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer"
            value="1"
            type="radio"
            name="urgentIssue"
            id="urgentIssue"
            v-model="page_data.action"
          />
          <label
            @click="focusScan"
            class="form-check-label inline-block text-black text-md"
            for="urgentIssue"
          >
            Urgent Issue
          </label>
        </div>
        <div class="form-check ml-6">
          <input
            class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer"
            value="2"
            type="radio"
            name="urgentRelease"
            id="urgentRelease"
            v-model="page_data.action"
          />
          <label
            class="form-check-label inline-block text-black text-md"
            for="urgentRelease"
          >
            Urgent Release
          </label>
        </div>
      </div>
      <hr>
          <div>
              <div class="grid grid-cols-12 gap-5">
                  <!-- <div class="col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-2">
                          <h2 class="text-lg font-bold">Available Packets</h2>
                      </div> -->
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Packet / Lot</label>
                          <input id="tabulator-html-filter-value" v-model="filter_data.packet_no" type="text" class="form-control 2xl:w-full" placeholder="Stock No" />
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Select kapan</label>
                          <TomSelect v-model="filter_data.kapan_list" :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Kapan'
                      }
                    }
                  }" multiple class="w-full">
                              <option v-for="(item, index) in page_data.kapan_details" :key="index" :value="item.id">{{item.lot_no}} ({{ item.voucher_no }})</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Packet Type</label>
                          <TomSelect v-model="filter_data.packet_type" :options="{
                    placeholder: 'Select Packet Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Packet Type'
                      }
                    }
                  }" class="w-full">
                              <option value="0">Packet Type</option>
                              <option value="makeable">Makeable</option>
                              <option value="rough">Rough</option>
                              <!-- <option value="video">Video</option> -->
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Weight Range</label>
                          <input id="tabulator-html-filter-value" v-model="filter_data.weight_range" type="text" class="form-control 2xl:w-full" placeholder="1.23,2-3,..." />
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Polish Weight</label>
                          <input id="tabulator-html-filter-value" v-model="filter_data.ex_weight_range" type="text" class="form-control 2xl:w-full" placeholder="1.23,2-3,..." />
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Shape</label>
                          <TomSelect v-model="filter_data.shape_range" :options="{
                    placeholder: 'Select Shape',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Shape'
                      }
                    }
                  }" multiple class="w-full">
                              <option v-for="(item, index) in page_data.shape_list" :key="index" :value="item.id">{{item.shape_name}}</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Color</label>
                          <TomSelect v-model="filter_data.color_range" :options="{
                    placeholder: 'Select Color',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Color'
                      }
                    }
                  }" multiple class="w-full">
                              <option v-for="(item, index) in page_data.color_list" :key="index" :value="item.id">{{item.color_name}}</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Purity</label>
                          <TomSelect v-model="filter_data.purity_range" :options="{
                    placeholder: 'Select Purity',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Purity'
                      }
                    }
                  }" multiple class="w-full">
                              <option v-for="(item, index) in page_data.purity_list" :key="index" :value="item.id">{{item.purity_name}}</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Process</label>
                          <TomSelect v-model="filter_data.process_list" :options="{
                    placeholder: 'Select Process',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" multiple class="w-full">
                              <option v-for="(item, index) in page_data.processes" :key="index" :value="item.id">{{item.process_name}}</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div>
                        <label for="update-profile-form-6" class="form-label">Manager</label>
                          <TomSelect v-model="filter_data.manager_list" :options="{
                    placeholder: 'Select Manager',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Manager'
                      }
                    }
                  }" multiple class="w-full">
                              <option v-for="(item, index) in page_data.managers" :key="index" :value="item.id">{{item.first_name}} {{item.last_name}}</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                      <div class="mt-7">
                          <button class="btn btn-secondary mr-2" @click="resetFilters">
                              Reset
                          </button>
                          <button class="btn btn-primary " @click="initTabulator">
                              Generate
                          </button>
                      </div>
                  </div>
                  <div class="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-3">
                      <div class="flex flex-wrap mt-7">

                          <div class="text-sm font-bold mr-4">Total Stock: {{ page_data.total.total_packets ? page_data.total.total_packets : 0 }}</div>
                          <div class="text-sm font-bold">Total WT(Ct): {{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) : 0 }} Ct</div>
                      </div>
                  </div>
              </div>
          </div>
          <div v-if="page_data.action" class="flex justify-end text-right items-center w-100 mt-2">
            <button class="btn btn-primary " @click="submitForm">
                              {{ page_data.action == 1 ? "Issue Urgent" : "Release Stocks"}}
                          </button>
          </div>

          <div class="overflow-x-auto scrollbar-hidden">
              <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
  </div>
  </template>

  <script>
  /* eslint-disable */
  import {
      BASE_URL
  } from '@/constants/config'
  import axios from 'axios'
  import cash from 'cash-dom'
  import {
      computed,
      defineComponent,
      inject,
      onMounted,
      reactive,
      ref,
      toRef,
      watch
  } from 'vue'
  import print from 'vue3-print-nb'
  import feather from 'feather-icons'
  import Tabulator from 'tabulator-tables'
  import Toastify from 'toastify-js'
  import store from '@/store'
  import router from '@/router'
  import user from '@/store/user'
import { stringify } from 'postcss'
  export default defineComponent({
      directives: {
          print
      },
      setup() {
          const user_details = computed(() => store.state.user.currentUser)
          const swal = inject('$swal')
          const page_data = reactive({
              search: '',
              search_type: '',
              check: false,
              type: '',
              addlist: [],
              packet_list: [],
              addornot: false,
              employees: [],
              processes: [],
              parties: [],
              submit: false,
              managers: [],
              message: '',
              error: '',

              shape_list: [],
              color_list: [],
              purity_list: [],

              total: [],
              kapan_details: [],
          })
          // const success = toRef(page_data, 'success')
          const filter_data = reactive({

            "weight_range": "",
            "ex_weight_range": "",
            "packet_type": "",
            "color_range": [],
            "purity_range": [],
            "shape_range": [],
            "kapan_list": [],
            "packet_no": "",
            "process_list": [],
            "manager_list": [],
            "vendor_list": []
          })
          const tableRef = ref()
          const tabulator = ref()

          const initTabulator = async () => {
            if(page_data.action == "") {
              swal("Warning", "Please select action type first", "warning")
            } else {

              let filter_params = {
                  type: page_data.action,
                  weight_range: window.btoa(filter_data.weight_range),
                  ex_weight_range: window.btoa(filter_data.ex_weight_range),
                  color_range: JSON.stringify(filter_data.color_range),
                  purity_range: JSON.stringify(filter_data.purity_range),
                  shape_range: JSON.stringify(filter_data.shape_range),
                  kapan_list: JSON.stringify(filter_data.kapan_list),
                  packet_no: window.btoa(filter_data.packet_no),
                  packet_type: window.btoa(filter_data.packet_type),
                  process_list: JSON.stringify(filter_data.process_list),
                  manager_list: JSON.stringify(filter_data.manager_list),
                  vendor_list: JSON.stringify(filter_data.vendor_list)
              }

                tabulator.value = new Tabulator(tableRef.value, {
                    ajaxURL: BASE_URL + 'api/urgent/stocks_list',
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            "Content-type": 'application/json; charset=utf-8', //set specific content type
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                    },
                    ajaxParams: filter_params,
                    ajaxFiltering: true,
                    ajaxSorting: true,
                    printAsHtml: true,
                    printStyled: true,
                    headerSort: false,
                    // pagination: 'remote',
                    // paginationSize: 20,
                    // paginationSizeSelector: [20, 30, 40],
                    layout: 'fitColumns',
                    // responsiveLayout: 'collapse',
                    placeholder: 'No matching records found',
                    columns: [
                        // For HTML table
                        {formatter:"rowSelection", width: 100, titleFormatter:"rowSelection", hozAlign:"center", headerSort:false, cellClick:function(e, cell){
                          cell.getRow().toggleSelect();
                        }},
                        {
                            title: 'Packet No',
                            responsive: 0,
                            minWidth: 120,
                            field: 'number',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap"> <i data-feather="alert-circle" title="Urgent Packet" class="w-5 h-5 font-bold text-theme-6 mr-1"></i> ${
                  cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span> ' : cell.getData().number
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Stock No',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 130,
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Type',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 100,
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().type
                  }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Cr.Weight(Ct)',
                            responsive: 0,
                            field: 'cr_weight',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 130,
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().cr_weight
                  }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Exp. Wt',
                            responsive: 0,
                            field: 'cr_polish_weight',
                            minWidth: 130,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Shp',
                            field: 'shape_name',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Clr',
                            field: 'color_name',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Prt',
                            field: 'purity_name',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Stone',
                            field: 'stone_name',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Manager',
                            field: 'manager_name',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Process',
                            field: 'process_name',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Employee',
                            field: 'employee_name',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        }
                    ],
                    renderComplete() {
                        feather.replace({
                            'stroke-width': 1.5
                        })
                    }
                })

              let promise = axios({
                  url: "api/urgent/stocks_total",
                  method: "post",
                  data: filter_params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      console.log(data);
                      page_data.total = data.total
                  })
                  .catch(_error => {
                      page_data.total = []
                  });
            }
          }

          const getLotDetails = () => {
              axios({
                  url: 'api/master/dp_lot_details',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.kapan_details = res.data.items
              })
          }
          const getProcessData = () => {
              const params = {
              }
              axios({
                  url: 'api/master/dp_process',
                  method: 'post',
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.processes = res.data.items
              })
          }

          const getAllManager = () => {
              axios({
                  url: 'api/master/dp_managers',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.managers = res.data.items
              })
          }

          function getDiamontData() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        page_data.color_list = res.data.color
        page_data.purity_list = res.data.purity
        page_data.shape_list = res.data.shape
      })
    }

          const resetFilters = () => {
              filter_data.weight_range =  "";
              filter_data.ex_weight_range =  "";
              filter_data.packet_type =  "";
              filter_data.color_range =  [];
              filter_data.purity_range =  [];
              filter_data.shape_range =  [];
              filter_data.kapan_list =  [];
              filter_data.packet_no =  "";
              filter_data.process_list =  [];
              filter_data.manager_list =  [];
              filter_data.vendor_list =  [];

              // initTabulator()
          }

          const basicNonStickyNotificationPacket = () => {
              Toastify({
                  node: cash('#basic-non-sticky-notification-packet')
                      .clone()
                      .removeClass('hidden')[0],
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top',
                  position: 'right',
                  stopOnFocus: true
              }).showToast()
          }
          const basicNonStickyNotificationPacketAssign = () => {
              Toastify({
                  node: cash('#basic-non-sticky-notification-packet-assign')
                      .clone()
                      .removeClass('hidden')[0],
                  duration: 3000,
                  newWindow: true,
                  close: true,
                  gravity: 'top',
                  position: 'right',
                  stopOnFocus: true
              }).showToast()
          }

          const submitForm = () => {

            let selectedData = tabulator.value.getSelectedData(); // Fetch selected data

            if(selectedData.length > 0) {

              console.log(selectedData);

              const ids = selectedData.map(item => item.id);

              swal({
                  title: `Are you sure?`,
                  text: `you are about to ${page_data.action == 1 ? "Issue" : "Release"} stocks`,
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Confirm & Accept",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  preConfirm: login => {

                      let promise = axios({
                          url: "api/urgent/multiple_action",
                          method: "post",
                          data: {
                            ids: ids,
                            action: page_data.action
                          },
                          baseURL: BASE_URL,
                          headers: {
                              Authorization: "Bearer " + localStorage.getItem("token")
                          },
                          config: {
                              headers: {
                                  Accept: "application/json"
                              }
                          }
                      });
                      return promise
                          .then(result => result.data)
                          .then(data => {
                              if (data.status == "error") {
                                  swal("Error!", data.message, "error");
                              } else {
                                  swal("Success!", data.message, "success");
                                  initTabulator()
                              }
                          })
                          .catch(_error => {
                              return [];
                          });
                  },
                  allowOutsideClick: () => !swal.isLoading()
              }).then(result => {
                  if (result.value) {
                      //swal("Success!", "User Password Updated.", "success");
                  }
              });
            } else {
              swal("Warning", "Please Select records first", "warning")
            }



          }

          onMounted(() => {
              getAllManager()
              getDiamontData()
              getProcessData()
              getLotDetails()
          })

          return {
              page_data,
              filter_data,
              user_details,
              tabulator,
              tableRef,
              initTabulator,
              resetFilters,
              basicNonStickyNotificationPacketAssign,
              basicNonStickyNotificationPacket,
              submitForm
          }
      }
  })
  </script>
