<template>
<!-- BEGIN: Profile Info -->
<div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
    <div class="font-medium text-primary-3" v-if="page_data.error">{{ page_data.error }}</div>
</div>
<div id="basic-non-sticky-notification-packet-assign" class="toastify-content hidden flex flex-col sm:flex-row">
    <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black">{{ page_data.message }}</div>
    </div>
</div>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Available Stock</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <div class="text-sm font-bold mr-2 mt-5" v-if="page_data.packet_list.length > 0">Selected Stock : {{ page_data.packet_list.length }}</div>
            <div class="flex items-center mt-2 ml-2">
                <div class="mr-2 flex items-center">
                    <input id="remember-me" type="checkbox" v-model="page_data.check" class="form-check-input border mr-2 bg-white" />
                    <label for="remember-me" class="text-md font-medium">Manual</label>
                </div>
            </div>
            <div class="w-full flex sm:w-auto mt-3 ml-2">
                <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
                    <input type="text" v-model="page_data.search" @change="getPacketDetailsScan" autofocus ref="focusMe" :disabled="page_data.scan_disable == true" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Scan Packet" />
                    <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                </div>
                <button class="btn btn-primary shadow-md mr-2" @click="add_stock_to_list">Add</button>
                <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" @click="getProcessData" data-target="#new-process-modal" v-if="page_data.packet_list.length > 0">Assign Packets</a>
            </div>
        </div>
    </div>

    <!-- Begin: Scanned Lot Table -->
    <div class="intro-y box py-5 px-3 mt-5" v-if="page_data.packet_list.length > 0">
      <div class="overflow-auto">
        <table class="table w-full border">
            <thead>
                <tr>
                    <th class="whitespace-nowrap">Pkt No</th>
                    <th class="whitespace-nowrap">Stock No</th>
                    <th class="whitespace-nowrap">Cr. Weight</th>
                    <th class="whitespace-nowrap">Exp. Wt</th>
                    <th class="whitespace-nowrap">Shape</th>
                    <th class="whitespace-nowrap">Color</th>
                    <th class="whitespace-nowrap">Purity</th>
                    <th class="whitespace-nowrap">Length</th>
                    <th class="whitespace-nowrap">Width</th>
                    <th class="whitespace-nowrap">Height</th>
                    <th class="whitespace-nowrap">Comment</th>
                    <th class="whitespace-nowrap">Issue Comment</th>
                    <th class="whitespace-nowrap"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(packet, index) in page_data.packet_list" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
                    <td class="whitespace-nowrap" :class="{'text-theme-6' : packet.urgent}">{{ packet.number }}
                      <AlertCircleIcon class="w-5 h-5 font-bold text-theme-6 mr-1" title="Urgent Packet" v-if="packet.urgent == 1" />
                    </td>
                    <td class="whitespace-nowrap">{{ packet.vepari_no ? packet.vepari_no : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.cr_weight ? packet.cr_weight : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.cr_polish_weight ? packet.cr_polish_weight : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.shape ? packet.shape : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.color ? packet.color : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.purity ? packet.purity : '-' }}</td>
                    <!-- <td class="whitespace-nowrap">{{ packet.cut ? packet.cut : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.polish ? packet.polish : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.symmetry ? packet.symmetry : '-' }}</td> -->
                    <td class="whitespace-nowrap">{{ packet.length ? $h.myFloat(packet.length ? packet.length : 0) : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.width ? $h.myFloat(packet.width ? packet.width : 0) : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.height ? $h.myFloat(packet.height ? packet.height : 0) : '-' }}</td>
                    <td class="whitespace-nowrap">{{ packet.comment ? packet.comment : '-' }}</td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="packet.new_comment" class="form-control rounded-none" placeholder="Comment" aria-describedby="input-group-3" /></td>
                    <td class="py-2 font-medium text-center">
                        <div class="flex justify-start items-center p-0 m-0">
                          <a href="javascript:;" @click="removeItem(index)" class="btn btn-danger btn-sm mx-2">
                                <XIcon class="w-4 h-4" /></a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
    <!-- End: Scanned Lot Table -->
    <!-- <div class="grid grid-cols-12 gap-5 mt-5">
        <div class="col-span-6 xl:col-span-3 md:col-span-3 sm:col-span-4 box p-2 px-3 cursor-pointer zoom-in" v-for="(add,index) in page_data.packet_list" :key="index">
            <div class="flex justify-between items-center">
                <div class="font-medium text-base">{{ add.number }} ({{ add.vepari_no }}) <br>{{ (add.cr_weight ? add.cr_weight : 0) + ' Ct' }}</div>
                <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)">
                    <Trash2Icon class="w-4 h-4 mr-1" /></button>
            </div>
        </div>
    </div> -->
    <!-- BEGIN: Data List -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div>
            <div class="grid grid-cols-12 gap-5">
                <!-- <div class="col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-2">
                        <h2 class="text-lg font-bold">Available Packets</h2>
                    </div> -->
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                        <input id="tabulator-html-filter-value" v-model="filter_data.stock_no" type="text" class="form-control 2xl:w-full" placeholder="Stock No" />
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                        <TomSelect v-model="filter_data.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full">
                            <option value="0">Select Kapan</option>
                            <option v-for="(item, index) in page_data.kapan_details" :key="index" :value="item.id">{{item.lot_no}} ({{ item.voucher_no }})</option>
                        </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                        <TomSelect v-model="filter_data.packet_type" :options="{
                  placeholder: 'Select Packet Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Packet Type'
                    }
                  }
                }" class="w-full">
                            <option value="0">Packet Type</option>
                            <option value="makeable">Makeable</option>
                            <option value="rough">Rough</option>
                            <!-- <option value="video">Video</option> -->
                        </TomSelect>
                    </div>
                </div>
                <div class="col-span-2">
                    <div>
                      <input id="remember-me" type="checkbox" v-model="filter_data.for_p" class="form-check-input border mr-2 bg-white" />
                    <label for="remember-me" class="text-md font-medium">4P</label>
                    </div>
                    <div>
                      <input id="remember-me" type="checkbox" v-model="filter_data.semi_polish" class="form-check-input border mr-2 bg-white" />
                    <label for="remember-me" class="text-md font-medium">Semi Polish</label>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                    <div>
                        <button class="btn btn-secondary mr-2" @click="resetFilters">
                            Reset
                        </button>
                        <button class="btn btn-primary " @click="initTabulator">
                            Generate
                        </button>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-3">
                    <div class="flex flex-wrap">

                        <div class="text-sm font-bold mr-4">Total Stock: {{ page_data.total.total_packets ? page_data.total.total_packets : 0 }}</div>
                        <div class="text-sm font-bold">Total WT(Ct): {{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) : 0 }} Ct</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
<!-- BEGIN: new Process Modal -->
<div id="new-process-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <a data-dismiss="modal" href="javascript:;">
                <XIcon class="w-8 h-8 text-gray-500" />
            </a>
            <div class="modal-body p-10 text-center">
                <div class="intro-y">
                    <!-- <div id="vertical-form"> -->
                    <div class="flex flex-col justify-between">
                        <!-- BEGIN: Data List -->
                        <div class="intro-y overflow-auto">
                            <div class="flex">
                            </div>
                        </div>
                        <div class="p-5">
                            <div>
                                <div class="text-left">Transaction Type</div>
                                <TomSelect v-model="process.transaction_type" :options="{
                          placeholder: 'Select Transaction Type'
                        }" class="w-full">
                                    <option value="0">Process Assign</option>
                                    <option value="1">Manager Transfer</option>
                                    <option value="2">Job Done</option>
                                </TomSelect>
                            </div>
                            <div class="mt-3" v-if="process.transaction_type == '1'">
                                <div class="text-left">Select Manager</div>
                                <TomSelect v-model="process.manager_id" @change="validateManagerId" :options="{
                          placeholder: 'Select Manager'
                        }" class="w-full">
                                    <option value="0">Select Manager</option>
                                    <option v-for="(manager,ind) in page_data.managers" :key="ind" :value="manager.id">{{ manager.first_name + ' ' + manager.last_name }}</option>
                                </TomSelect>
                                <!-- <span class="text-primary-3 mb-2" v-if="errors.manager_id">{{ errors.manager_id[0] }}</span> -->
                            </div>
                            <div class="mt-3" v-if="process.transaction_type == '0'">
                                <div class="text-left">Select Process</div>
                                <TomSelect v-model="process.process_id" @change="getEmployees(process.process_id)" :options="{
                          placeholder: 'Select Process'
                        }" class="w-full">
                                    <option value="0">Select Process</option>
                                    <option v-for="(process,ind) in page_data.processes" :key="ind" :value="process.id">{{ process.process_name }}</option>
                                </TomSelect>
                                <!-- <span class="text-primary-3 mb-2" v-if="errors.process_id">{{ errors.process_id[0] }}</span> -->

                            </div>
                            <div class="flex items-center mt-3" v-if="process.process_id != '0' && process.transaction_type == '0'">
                                <div class="form-check">
                                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="singleinternal" id="singleinternal" v-model="process.eternals">
                                    <label class="form-check-label inline-block text-black text-lg" for="singleinternal">
                                        Internal Employee
                                    </label>
                                </div>
                                <div class="form-check ml-6">
                                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="singleexternal" id="singleexternal" v-model="process.eternals">
                                    <label class="form-check-label inline-block text-black text-lg" for="singleexternal">
                                        Job Party
                                    </label>
                                </div>
                            </div>
                            <div class="mt-3" v-if="process.eternals == '0' && process.process_id != '0' && process.transaction_type == '0'">
                              <div class="text-left">Select Employee</div>
                                <TomSelect v-model="process.employee_id" :options="{
                            placeholder: 'Select Employee'
                          }" class="w-full">
                                    <option value="0">Select Employee</option>
                                    <option v-for="(emp,ind) in page_data.employees" :key="ind" :value="emp.id">{{ emp.first_name + ' ' + emp.last_name}}</option>
                                </TomSelect>
                                <!-- <span class="text-primary-3 mb-2" v-if="errors.employee_id">{{ errors.employee[0] }}</span> -->
                            </div>
                            <div class="mt-3" v-else-if="process.eternals == '1' && process.process_id != '0' && process.transaction_type == '0'">
                              <div class="text-left">Select Job Party</div>
                                <TomSelect v-model="process.party_id" :options="{
                        placeholder: 'Select Party'
                      }" class="w-full">
                                    <option value="0">Select Party</option>
                                    <option v-for="(party,ind) in page_data.parties" :key="ind" :value="party.id">{{ party.firm_name }}</option>
                                </TomSelect>
                                <!-- <span class="text-primary-3 mb-2" v-if="errors.party_id">{{ errors.party_id[0] }}</span> -->
                            </div>
                        </div>
                        <button type="button" @click="AssignProcess" :disabled="page_data.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                            <LoadingIcon v-if="page_data.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END: new Process Modal -->
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import {
    computed,
    defineComponent,
    inject,
    onMounted,
    reactive,
    ref,
    toRef,
    watch
} from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import store from '@/store'
import router from '@/router'
import user from '@/store/user'
export default defineComponent({
    directives: {
        print
    },
    setup() {
        const user_details = computed(() => store.state.user.currentUser)
        const focusMe = ref()
        const swal = inject('$swal')
        const page_data = reactive({
            search: '',
            search_type: '',
            check: false,
            type: '',
            addlist: [],
            packet_list: [],
            addornot: false,
            employees: [],
            processes: [],
            parties: [],
            submit: false,
            managers: [],
            message: '',
            error: '',

            total: [],
            kapan_details: [],
        })
        // const success = toRef(page_data, 'success')
        const filter_data = reactive({
            kapan_id: '0',
            stock_no: '',
            packet_type: '0',
            for_p: false,
            semi_polish: false,
        })
        const process = reactive({
            eternals: '0',
            manager_id: '0',
            process_id: '0',
            employee_id: '0',
            party_id: '0',
            transaction_type: '0',
            type: ''
        })
        const tableRef = ref()
        const tabulator = ref()

        const initTabulator = async () => {

            let filter_params = {
                kapan_id: filter_data.kapan_id == '0' ? '' : window.btoa(filter_data.kapan_id),
                stock_no: filter_data.stock_no == '' ? '' : window.btoa(filter_data.stock_no),
                packet_type: filter_data.packet_type == '0' ? '' : window.btoa(filter_data.packet_type),
                for_p: filter_data.for_p,
                semi_polish: filter_data.semi_polish
            }

            if (user_details.value.user_role == 5 || user_details.value.user_role == 6) {
                tabulator.value = new Tabulator(tableRef.value, {
                    ajaxURL: BASE_URL + 'api/purchase/get_available_packets',
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            "Content-type": 'application/json; charset=utf-8', //set specific content type
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                    },
                    ajaxParams: filter_params,
                    ajaxFiltering: true,
                    ajaxSorting: true,
                    printAsHtml: true,
                    printStyled: true,
                    headerSort: false,
                    pagination: 'remote',
                    paginationSize: 20,
                    paginationSizeSelector: [20, 30, 40],
                    layout: 'fitColumns',
                    responsiveLayout: 'collapse',
                    placeholder: 'No matching records found',
                    columns: [{
                            formatter: 'responsiveCollapse',
                            width: 40,
                            minWidth: 30,
                            hozAlign: 'center',
                            resizable: false,
                            headerSort: false
                        },

                        // For HTML table
                        {
                            title: 'Packet No',
                            responsive: 0,
                            minWidth: 120,
                            field: 'number',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().urgent == 1 ? '<span class="text-theme-6"> <i data-feather="alert-circle" title="Urgent Packet" class="w-5 h-5 font-bold text-theme-6 mr-1"></i> '+ cell.getData().number +'</span> ' : cell.getData().number
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Stock No',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Type',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().type
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Weight',
                            responsive: 0,
                            field: 'cr_weight',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().cr_weight
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Stn',
                            field: 'stone',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false
                        },
                        {
                            title: 'Action',
                            minWidth: 80,
                            field: '',
                            responsive: 1,
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            headerSort: false,
                            print: false,
                            download: false,
                            formatter(cell) {
                                if (cell.getData().last_planner == user_details.value.account_id) {
                                    const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-primary btn-sm flex items-center mr-3">
                        <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit Plan
                      </button>
                    </div>`)
                                    cash(a).on('click', function () {
                                        cash('#new-manual-planning').modal('show')
                                    })
                                    return a[0]
                                } else {
                                    const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-primary btn-sm flex items-center mr-3">
                        <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Add Plan
                        </button>
                        </div>`)
                                    cash(a).on('click', function () {
                                        cash('#new-manual-planning').modal('show')
                                    })
                                    return a[0]
                                }
                            }
                        },
                    ],
                    renderComplete() {
                        feather.replace({
                            'stroke-width': 1.5
                        })
                    }
                })
            } else if (user_details.value.user_role == 4) {
                if (user_details.value.user_role == 5 || user_details.value.user_role == 6) {
                    tabulator.value = new Tabulator(tableRef.value, {
                        ajaxURL: BASE_URL + 'api/purchase/get_available_packets',
                        ajaxConfig: {
                            method: "GET", //set request type to Position
                            headers: {
                                "Content-type": 'application/json; charset=utf-8', //set specific content type
                                "Authorization": "Bearer " + localStorage.getItem("token")
                            },
                        },
                        ajaxParams: filter_params,
                        ajaxFiltering: true,
                        ajaxSorting: true,
                        printAsHtml: true,
                        printStyled: true,
                        headerSort: false,
                        pagination: 'remote',
                        paginationSize: 20,
                        paginationSizeSelector: [20, 30, 40],
                        layout: 'fitColumns',
                        responsiveLayout: 'collapse',
                        placeholder: 'No matching records found',
                        columns: [{
                                formatter: 'responsiveCollapse',
                                width: 40,
                                minWidth: 30,
                                hozAlign: 'center',
                                resizable: false,
                                headerSort: false
                            },

                            // For HTML table
                            {
                                title: 'Packet No',
                                responsive: 0,
                                minWidth: 120,
                                field: 'number',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().urgent == 1 ? '<span class="text-theme-6"> <i data-feather="alert-circle" title="Urgent Packet" class="w-5 h-5 font-bold text-theme-6 mr-1"></i> '+ cell.getData().number +'  </span>' : cell.getData().number
                }</div>
              </div>`
                                }
                            },
                            {
                                title: 'Stock No',
                                responsive: 0,
                                field: 'type',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
                                }
                            },
                            {
                                title: 'Type',
                                responsive: 0,
                                field: 'type',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().type
                }</div>
              </div>`
                                }
                            },
                            {
                                title: 'Weight',
                                responsive: 0,
                                field: 'cr_weight',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false,
                                formatter(cell) {
                                    return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().cr_weight
                }</div>
              </div>`
                                }
                            },
                            {
                                title: 'Stn',
                                field: 'stone',
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                print: false,
                                download: false
                            },
                            {
                                title: 'Action',
                                minWidth: 80,
                                field: '',
                                responsive: 1,
                                hozAlign: 'center',
                                vertAlign: 'middle',
                                headerSort: false,
                                print: false,
                                download: false,
                                formatter(cell) {
                                    if (cell.getData().last_planner == user_details.value.account_id) {
                                        const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-primary btn-sm flex items-center mr-3">
                        <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit Plan
                      </button>
                    </div>`)
                                        cash(a).on('click', function () {
                                            cash('#new-manual-planning').modal('show')
                                        })
                                        return a[0]
                                    } else {
                                        const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-primary btn-sm flex items-center mr-3">
                        <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Add Plan
                        </button>
                        </div>`)
                                        cash(a).on('click', function () {
                                            cash('#new-manual-planning').modal('show')
                                        })
                                        return a[0]
                                    }
                                }
                            },
                        ],
                        renderComplete() {
                            feather.replace({
                                'stroke-width': 1.5
                            })
                        }
                    })
                }
            } else {
                tabulator.value = new Tabulator(tableRef.value, {
                    ajaxURL: BASE_URL + 'api/purchase/get_available_packets',
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            "Content-type": 'application/json; charset=utf-8', //set specific content type
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                    },
                    ajaxParams: filter_params,
                    ajaxFiltering: true,
                    ajaxSorting: true,
                    printAsHtml: true,
                    printStyled: true,
                    headerSort: false,
                    pagination: 'remote',
                    paginationSize: 20,
                    paginationSizeSelector: [20, 30, 40],
                    layout: 'fitColumns',
                    // responsiveLayout: 'collapse',
                    placeholder: 'No matching records found',
                    columns: [
                        // {
                        //       formatter: 'responsiveCollapse',
                        //       width: 40,
                        //       minWidth: 30,
                        //       hozAlign: 'center',
                        //       resizable: false,
                        //       headerSort: false
                        //   },

                        // For HTML table
                        {
                            title: 'Packet No',
                            responsive: 0,
                            minWidth: 130,
                            field: 'number',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().urgent == 1 ? '<span class="text-theme-6"> <i data-feather="alert-circle" title="Urgent Packet" class="w-5 h-5 font-bold text-theme-6 mr-1"></i> '+ cell.getData().number +' </span>' : cell.getData().number
                  }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Stock No',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 130,
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Type',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 100,
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().type
                  }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Cr.Weight(Ct)',
                            responsive: 0,
                            field: 'cr_weight',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 130,
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().cr_weight
                  }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Exp. Wt',
                            responsive: 0,
                            field: 'cr_polish_weight',
                            minWidth: 130,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Shp',
                            field: 'shape',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Clr',
                            field: 'color',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Prt',
                            field: 'purity',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Ct',
                            field: 'cut',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Pl',
                            field: 'polish',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Sym',
                            field: 'symmetry',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        {
                            title: 'Fluc',
                            field: 'fluerocent',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            minWidth: 90,
                            print: false,
                            download: false
                        },
                        // {
                        //     title: 'Tn',
                        //     field: 'tension',
                        //     hozAlign: 'center',
                        //     vertAlign: 'middle',
                        //     print: false,
                        //     download: false
                        // },
                        // {
                        //     title: 'Stn',
                        //     field: 'stone',
                        //     hozAlign: 'center',
                        //     vertAlign: 'middle',
                        //     print: false,
                        //     download: false
                        // },
                    ],
                    renderComplete() {
                        feather.replace({
                            'stroke-width': 1.5
                        })
                    }
                })
            }

            let promise = axios({
                url: "api/purchase/available_packets_total",
                method: "post",
                data: filter_params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    console.log(data);
                    page_data.total = data.total
                })
                .catch(_error => {
                    page_data.total = []
                });
        }

        const add_stock_to_list = () => {
            if (page_data.search != '') {
                if (page_data.addlist.length <= 0) {
                    verify_stock()
                } else {
                    for (let i = 0; i < page_data.packet_list.length; i++) {
                        if (page_data.packet_list[i].number == page_data.search || page_data.packet_list[i].barnum == page_data.search || page_data.packet_list[i].vepari_no == page_data.search) {
                            swal("Warning", "Scanned stock already in list", "warning")
                            page_data.search = ''
                            return;
                        } else {
                            page_data.addornot = false
                        }

                    }
                    if (page_data.addornot == false) {
                        verify_stock()
                    }
                }
            }
        }

        const verify_stock = () => {
            const params = {
                type: window.btoa(page_data.search_type),
                number: window.btoa(page_data.search),
            }
            axios({
                url: 'api/purchase/scan_available_packets',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                const index = ref(page_data.addlist.length)
                page_data.addlist[index.value] = res.data.packet.number
                page_data.packet_list[index.value] = res.data.packet
                page_data.search_type = res.data.packet.type
                page_data.search = ''
                focusMe.value.focus()
                // page_data.checklist[page_data.addlist[index.value].id] = true
            }).catch((err) => {
                page_data.error = err.response.data.error
                page_data.search = ''
                focusMe.value.focus()
                console.warn(page_data.error)
                setTimeout(() => {
                    basicNonStickyNotificationPacket()
                }, 100)
                // setTimeout(() => {
                //   page_data.search = ''
                // }, 1000);
                setTimeout(() => {
                    page_data.error = ''
                }, 3000);

            })
        }

        const removeItem = (id) => {

            page_data.addlist.splice(id, 1);
            page_data.packet_list.splice(id, 1);

            if (page_data.addlist.length <= 0) {
                page_data.search_type = ''
            }
        }

        const getLotDetails = () => {
            axios({
                url: 'api/master/dp_lot_details',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.kapan_details = res.data.items
            })
        }

        const getTransferCheck = () => {
            axios({
                url: '/user/transferManager',
                method: 'get',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.managers = res.data.managers
            })
        }

        const AssignProcess = () => {
            const params = {
                stock_arr: page_data.packet_list,
                number: page_data.addlist,
                externals: window.btoa(process.eternals),
                process_id: process.process_id == '0' ? '' : window.btoa(process.process_id),
                employee_id: process.employee_id == '0' ? '' : window.btoa(process.employee_id),
                manager_id: process.manager_id == '0' ? '' : window.btoa(process.manager_id),
                party_id: process.party_id == '0' ? '' : window.btoa(process.party_id),
                transaction_type: window.btoa(process.transaction_type),
            }
            page_data.submit = true
            axios({
                url: 'api/process/manager_multiple_issue',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.message = res.data.success
                page_data.submit = false
                initTabulator()
                setTimeout(() => {
                    basicNonStickyNotificationPacketAssign()
                }, 100);
                cash('#new-process-modal').modal('hide')
                page_data.addlist = []
                page_data.packet_list = []
                page_data.search_type = ''
                process.employee_id = '0'
                process.manager_id = '0'
                process.process_id = '0'
                process.party_id = '0'
                process.eternals = '0'
                process.transaction_type = '0'
                setTimeout(() => {
                    page_data.message = ''
                }, 3000)
            }).catch((err) => {
                page_data.submit = false
                swal("Warning!", err.response.data.error, "warning");
            })
        }

        const getEmployees = () => {
            const params = {
                manager_id: window.btoa(user_details.value.account_id),
                process_id: window.btoa(process.process_id),
                process_type: window.btoa(page_data.search_type),
            }
            if (process.process_id != '0') {

                axios({
                    url: 'api/master/get_employees_by_manager_process',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    page_data.employees = res.data.items
                    process.employee_id = '0'
                })
                axios({
                    url: 'api/master/get_jober_details_by_process',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    page_data.parties = res.data.items
                    process.party_id = '0'
                })
            } else {
                page_data.employees = []
                page_data.parties = []
                process.party_id = '0'
                process.employee_id = '0'
            }
        }

        const getProcessData = () => {
            const params = {
                user_id: user_details.value.account_id,
                process_type: window.btoa(page_data.search_type)
            }
            axios({
                url: 'api/master/manager_processes',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.processes = res.data.items
                process.process_id = '0'
            })
        }

        const getAllManager = () => {
            axios({
                url: 'api/master/dp_managers',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.managers = res.data.items
                process.manager_id = '0'
            })
        }

        const validateManagerId = () => {
            if (user_details.value.account_id == process.manager_id) {
                process.manager_id = '0'
                swal("Warning!", "Self Transfre is Not Allowed !", "warning");
            }
        }

        const getPacketDetailsScan = () => {
            if (!page_data.check && page_data.search) {
                add_stock_to_list();
            }
        }

        const resetFilters = () => {
            filter_data.packet_type = '0'
            filter_data.kapan_id = '0'
            filter_data.stock_no = ''
            filter_data.for_p = false
            filter_data.semi_polish = false

            initTabulator()
        }

        const basicNonStickyNotificationPacket = () => {
            Toastify({
                node: cash('#basic-non-sticky-notification-packet')
                    .clone()
                    .removeClass('hidden')[0],
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true
            }).showToast()
        }
        const basicNonStickyNotificationPacketAssign = () => {
            Toastify({
                node: cash('#basic-non-sticky-notification-packet-assign')
                    .clone()
                    .removeClass('hidden')[0],
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true
            }).showToast()
        }

        onMounted(() => {
            initTabulator()
            getAllManager()
            getLotDetails()
            focusMe.value.focus()
        })

        return {
            page_data,
            filter_data,
            focusMe,
            user_details,
            process,
            getPacketDetailsScan,
            tableRef,
            add_stock_to_list,
            removeItem,
            getTransferCheck,
            getEmployees,
            getAllManager,
            getProcessData,
            AssignProcess,
            initTabulator,
            validateManagerId,
            resetFilters,
            basicNonStickyNotificationPacketAssign,
            basicNonStickyNotificationPacket,
        }
    }
    // ,
    // mounted() {
    //   this.$refs.focusMe.focus()
    // }
})
</script>
