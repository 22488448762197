<template>
  <!-- BEGIN: Profile Info -->
  <div
    id="basic-non-sticky-notification-packet"
    class="toastify-content hidden flex flex-col sm:flex-row"
  >
    <div class="font-medium text-primary-3" v-if="page_data.error">
      {{ page_data.error }}
    </div>
  </div>
  <div
    id="basic-non-sticky-notification-packet-assign"
    class="toastify-content hidden flex flex-col sm:flex-row"
  >
    <div class="flex items-center justify-around">
      <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
      <div class="font-medium text-black">{{ page_data.message }}</div>
    </div>
  </div>
  <div>
    <div class="intro-y items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Urgent Issue / Urgent Release</h2>
      <div class="flex items-center mt-3">
        <div class="form-check">
          <input
            @click="focusScan"
            class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer"
            value="1"
            type="radio"
            name="urgentIssue"
            id="urgentIssue"
            v-model="page_data.action"
          />
          <label
            @click="focusScan"
            class="form-check-label inline-block text-black text-md"
            for="urgentIssue"
          >
            Urgent Issue
          </label>
        </div>
        <div class="form-check ml-6">
          <input
            class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer"
            value="2"
            type="radio"
            name="urgentRelease"
            id="urgentRelease"
            v-model="page_data.action"
          />
          <label
            class="form-check-label inline-block text-black text-md"
            for="urgentRelease"
          >
            Urgent Release
          </label>
        </div>
      </div>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div class="flex items-center mt-2 ml-2">
          <div class="mr-2 flex items-center">
            <input
              id="remember-me"
              type="checkbox"
              v-model="page_data.check"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="remember-me" class="text-md font-medium">Manual</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="page_data.search"
              @change="getPacketDetailsScan"
              autofocus
              ref="focusMe"
              :disabled="page_data.scan_disable == true"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Scan Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button
            class="btn btn-primary shadow-md mr-2"
            @click="add_stock_to_list"
          >
            Add
          </button>
        </div>
        <div
          class="text-sm font-bold mr-2 mt-5"
          v-if="page_data.packet_list.length > 0"
        >
          Selected Stock : {{ page_data.packet_list.length }}
        </div>
      </div>
    </div>

    <!-- Begin: Scanned Lot Table -->
    <div
      class="intro-y box py-5 px-3 mt-5"
      v-if="page_data.packet_list.length > 0"
    >
      <div class="overflow-auto">
        <table class="table w-full border">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Pkt No</th>
              <th class="whitespace-nowrap">Stock No</th>
              <th class="whitespace-nowrap">Type</th>
              <th class="whitespace-nowrap">Cr. Weight</th>
              <th class="whitespace-nowrap">Exp. Wt</th>
              <th class="whitespace-nowrap">Shape</th>
              <th class="whitespace-nowrap">Color</th>
              <th class="whitespace-nowrap">Purity</th>
              <th class="whitespace-nowrap">Stone Type</th>
              <th class="whitespace-nowrap">Manager</th>
              <th class="whitespace-nowrap">Process</th>
              <th class="whitespace-nowrap">Employee</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(packet, index) in page_data.packet_list"
              :key="index"
              class="border hover:bg-gray-200 p-0 cursor-pointer"
            >
              <td
                class="whitespace-nowrap"
                :class="{ 'text-primary-3': packet.urgent == 1 }"
              >
                <AlertCircleIcon
                  class="w-5 h-5 font-bold text-theme-6 mr-1"
                  v-if="packet.urgent == 1"
                />
                {{ packet.number }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.vepari_no ? packet.vepari_no : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.type ? packet.type : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.cr_weight ? packet.cr_weight : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.cr_polish_weight ? packet.cr_polish_weight : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.shape_name ? packet.shape_name : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.color_name ? packet.color_name : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.purity_name ? packet.purity_name : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.stone_name ? packet.stone_name : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.manager_name ? packet.manager_name : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.process_name ? packet.process_name : '-' }}
              </td>
              <td class="whitespace-nowrap">
                {{ packet.employee_name ? packet.employee_name : '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- End: Scanned Lot Table -->
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, inject, reactive, ref } from 'vue'
import print from 'vue3-print-nb'
import Toastify from 'toastify-js'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const focusMe = ref()
    const swal = inject('$swal')
    const page_data = reactive({
      search: '',
      search_type: '',
      check: false,
      type: '',
      packet_list: [],
      addornot: false,
      employees: [],
      parties: [],
      submit: false,
      managers: [],
      message: '',
      error: '',
      action: '',

      total: [],
      kapan_details: []
    })

    const add_stock_to_list = () => {
      if (page_data.action == '') {
        swal.fire(
          'Warning',
          'Please select action type befor issue the packet !',
          'warning'
        )
      } else {
        if (page_data.search != '') {
          if (page_data.packet_list.length <= 0) {
            verify_stock()
          } else {
            for (let i = 0; i < page_data.packet_list.length; i++) {
              if (
                page_data.packet_list[i].number == page_data.search ||
                page_data.packet_list[i].barnum == page_data.search ||
                page_data.packet_list[i].vepari_no == page_data.search
              ) {
                swal('Warning', 'Scanned stock already in list', 'warning')
                page_data.search = ''
                return
              } else {
                page_data.addornot = false
              }
            }
            if (page_data.addornot == false) {
              verify_stock()
            }
          }
        }
      }
    }

    const verify_stock = () => {
      const params = {
        action: window.btoa(page_data.action),
        barcode: window.btoa(page_data.search)
      }
      axios({
        url: 'api/urgent/single_action',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
        .then(res => {
          const index = page_data.packet_list.length
          page_data.packet_list.push(res.data.item)
          page_data.search = ''
          focusMe.value.focus()
        })
        .catch(err => {
          page_data.error = err.response.data.error
          page_data.search = ''
          focusMe.value.focus()
          console.warn(page_data.error)
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          }, 100)
          setTimeout(() => {
            page_data.error = ''
          }, 3000)
        })
    }

    const getPacketDetailsScan = () => {
      if (page_data.action == '') {
        swal.fire(
          'Warning',
          'Please select action type befor issue the packet !',
          'warning'
        )
      } else {
        if (!page_data.check && page_data.search) {
          add_stock_to_list()
        }
      }
    }

    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const focusScan = () => {
      focusMe.value.focus()
    }

    return {
      page_data,
      focusMe,
      getPacketDetailsScan,
      add_stock_to_list,
      basicNonStickyNotificationPacket,
      focusScan
    }
  }
  // ,
  // mounted() {
  //   this.$refs.focusMe.focus()
  // }
})
</script>
